import React, { useState, useEffect } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Card,
    Typography,
    TextField,
    Button,
    Grid,
    Tooltip,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { formatDate } from "../../../utils/formatDate.utils";
import { useParams } from "react-router-dom";
import { apiGetCommunityStats } from "../../../services/apiGetCommunityStats";
import { Loader } from "../../../components/ui/Loader";

export const StatsReport = () => {
    const { id: communityId } = useParams();
    const [statsData, setStatsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

    const getFirstDayOfCurrentMonth = () => {
        const date = new Date();
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-01`;
    };

    const getLastDayOfCurrentMonth = () => {
        const date = new Date();
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(lastDay).padStart(2, "0")}`;
    };

    const [filters, setFilters] = useState({
        dataInicio: getFirstDayOfCurrentMonth(),
        dataFim: getLastDayOfCurrentMonth(),
    });

    const fetchData = async () => {
        try {
            setLoading(true);
            setIsUpdated(false);

            const response = await apiGetCommunityStats(communityId, filters);
            setStatsData(Array.isArray(response) ? response : []);
            setIsUpdated(true);
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
            setStatsData([]);
            setIsUpdated(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [communityId]);

    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFilter = () => {
        fetchData();
    };

    const exportToExcel = () => {
        const headers = [
            "Post",
            "Perfil/Professor",
            "Data Postagem",
            "Visualizações",
            "Curtidas",
            "Comentários",
            "Downloads",
        ];

        const data = sortedData.map((item) => [
            item.idPost,
            item.creator?.nome || "",
            formatDate(item.createdAt),
            item.visualizacoes,
            item.curtidas,
            item.comentarios,
            item.downloads,
        ]);

        const excelData = [headers, ...data];

        let csvContent = excelData.map((e) => e.join("\t")).join("\n");

        const BOM = "\uFEFF";
        const blob = new Blob([BOM + csvContent], { type: "text/csv;charset=utf-8" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `stats_${new Date().toISOString().split("T")[0]}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const sortedData = React.useMemo(() => {
        if (!statsData || !Array.isArray(statsData)) return [];

        let sortedItems = [...statsData];
        if (sortConfig.key) {
            sortedItems.sort((a, b) => {
                if (!a || !b) return 0;

                let valA, valB;

                if (sortConfig.key.includes(".")) {
                    const keys = sortConfig.key.split(".");
                    valA = keys.reduce((obj, key) => obj && obj[key], a);
                    valB = keys.reduce((obj, key) => obj && obj[key], b);
                } else {
                    valA = a[sortConfig.key];
                    valB = b[sortConfig.key];
                }

                if (valA < valB) {
                    return sortConfig.direction === "asc" ? -1 : 1;
                }
                if (valA > valB) {
                    return sortConfig.direction === "asc" ? 1 : -1;
                }
                return 0;
            });
        }
        return sortedItems;
    }, [statsData, sortConfig]);

    if (!isUpdated) {
        return <Loader />;
    }

    return (
        <Box>
            <Card sx={{ p: 3, mb: 3, mt: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
                    <Typography variant="h6">Dados sobre Posts da Comunidade</Typography>
                    <Tooltip title="Exportar para Excel">
                        <Button
                            variant="outlined"
                            startIcon={<DownloadIcon />}
                            onClick={exportToExcel}
                            sx={{
                                color: "var(--primary-color)",
                                borderColor: "var(--primary-color)",
                                "&:hover": {
                                    borderColor: "var(--primary-color-dark)",
                                    backgroundColor: "rgba(var(--primary-color-rgb), 0.04)",
                                    transform: "scale(1.02)",
                                    transition: "all 0.2s",
                                },
                            }}
                        >
                            Exportar
                        </Button>
                    </Tooltip>
                </Box>
                <Grid container spacing={2} sx={{ mb: 3 }}>
                    <Grid item xs={12} sm={5} md={5}>
                        <TextField
                            fullWidth
                            label="Data Início"
                            name="dataInicio"
                            type="date"
                            value={filters.dataInicio}
                            onChange={handleFilterChange}
                            InputLabelProps={{ shrink: true }}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                        <TextField
                            fullWidth
                            label="Data Fim"
                            name="dataFim"
                            type="date"
                            value={filters.dataFim}
                            onChange={handleFilterChange}
                            InputLabelProps={{ shrink: true }}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                        <Button
                            variant="contained"
                            onClick={handleFilter}
                            fullWidth
                            sx={{
                                height: "100%",
                                minHeight: "40px",
                                backgroundColor: "var(--primary-color)",
                                color: "white",
                                "&:hover": {
                                    backgroundColor: "var(--primary-dark-color)",
                                    color: "white",
                                },
                            }}
                        >
                            Filtrar
                        </Button>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={{ boxShadow: "none", overflow: "auto" }}>
                    <Table sx={{ minWidth: 650 }}>
                        <colgroup>
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "15%" }} />
                            <col style={{ width: "15%" }} />
                            <col style={{ width: "15%" }} />
                            <col style={{ width: "15%" }} />
                            <col style={{ width: "15%" }} />
                            <col style={{ width: "15%" }} />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell onClick={() => handleSort("idPost")} style={{ cursor: "pointer" }}>
                                    Conteúdo (id_anuncio)
                                </TableCell>
                                <TableCell onClick={() => handleSort("creator.nome")} style={{ cursor: "pointer" }}>
                                    Perfil/Professor
                                </TableCell>
                                <TableCell
                                    align="center"
                                    onClick={() => handleSort("createdAt")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Data Postagem
                                </TableCell>
                                <TableCell
                                    align="center"
                                    onClick={() => handleSort("visualizacoes")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Visualizações
                                </TableCell>
                                <TableCell
                                    align="center"
                                    onClick={() => handleSort("curtidas")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Curtidas
                                </TableCell>
                                <TableCell
                                    align="center"
                                    onClick={() => handleSort("comentarios")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Comentários
                                </TableCell>
                                <TableCell
                                    align="center"
                                    onClick={() => handleSort("downloads")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Downloads
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData.map((item) => (
                                <TableRow key={item.idPost}>
                                    <TableCell sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                                        {item.idPost}
                                    </TableCell>
                                    <TableCell sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                                        {item.creator?.nome || ""}
                                    </TableCell>
                                    <TableCell align="center" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                                        {formatDate(item.createdAt)}
                                    </TableCell>
                                    <TableCell align="center" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                                        {item.visualizacoes}
                                    </TableCell>
                                    <TableCell align="center" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                                        {item.curtidas}
                                    </TableCell>
                                    <TableCell align="center" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                                        {item.comentarios}
                                    </TableCell>
                                    <TableCell align="center" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                                        {item.downloads}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {sortedData.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        {loading ? "Carregando..." : "Nenhum dado encontrado"}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </Box>
    );
};
