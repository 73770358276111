import React, { useMemo } from "react";
import { Route, Routes as RouterRoutes, useLocation } from "react-router-dom";

import { Dashboard } from "../pages/dashboard/Dashboard";
import { Campanha } from "../components/campanhas/Campanha";
import { Campanhas } from "../components/campanhas/Campanhas";
import { ForgotPassword } from "../pages/forgotPassword";
import { Onboarding } from "../pages/onboarding";
import { CreateNewPassword } from "../pages/createNewPassword";
import { EditUserInfo } from "../pages/editUserInfo";
import { Community } from "../pages/community";
import { Ranking } from "../pages/ranking";
import { RankingDetail } from "../pages/ranking/detail";
import { CommunitiesList } from "../pages/communityList";
import { Learn } from "../pages/learn";
import { PostDetails } from "../pages/postDetails";
import { NewPost } from "../pages/newPost";
import { Gamification } from "../pages/gamification";
import { ManageCommunity } from "../pages/manageCommunity";
import { ManageCommunities } from "../pages/manageCommunities";
import { PostSuccess } from "../pages/postSuccess";
import { Opportunities } from "../pages/opportunities/opportunities";

const dashboardRoutes = [
    { path: "/", element: <Onboarding /> },
    { path: "/campanhas", element: <Campanhas /> },
    { path: "/campanhas/:id", element: <Campanha /> },
    { path: "/editUserInfo", element: <EditUserInfo /> },
    { path: "/aprenda", element: <Learn /> },
    { path: "/comunidades", element: <CommunitiesList /> },
    { path: "/comunidade/:id", element: <Community /> },
    { path: "/comunidade/:id/ranking", element: <Ranking /> },
    { path: "/ranking/creator", element: <RankingDetail /> },
    { path: "/comunidade/:id/gerenciar/*", element: <ManageCommunity /> },
    { path: "/post/:id", element: <PostDetails /> },
    { path: "/novoPost/:id", element: <NewPost /> },
    { path: "/gamification", element: <Gamification /> },
    { path: "/oportunidades", element: <Opportunities /> },
    { path: "/manageCommunity", element: <ManageCommunity /> },
    { path: "/comunidades/administradas", element: <ManageCommunities /> },
    { path: "/post-success/:id", element: <PostSuccess /> },
];

export const Routes = () => {
    const { pathname: currentPath } = useLocation();
    const token = useMemo(() => currentPath.split("/").pop(), [currentPath]);

    return (
        <RouterRoutes>
            {dashboardRoutes.map(({ path, element }) => (
                <Route key={path} path={path} element={<Dashboard>{element}</Dashboard>} />
            ))}
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/createNewPassword" element={<CreateNewPassword />} />
            <Route path={`/createNewPassword/${token}`} element={<CreateNewPassword />} />
        </RouterRoutes>
    );
};
