import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    CircularProgress,
    Avatar,
    Button,
    Divider,
    List,
    ListItem,
    ListItemText,
    IconButton,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as S from "./styles";
import { apiGetRankingCreator } from "../../services/apiGetRankingCreator";
import { formatDateString } from "../../utils/formatDateString";



export const RankingDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const creatorId = queryParams.get("creator");
    const communityId = queryParams.get("community");
    const periodoId = queryParams.get("periodo");

    const [creatorData, setCreatorData] = useState(null);
    const [pointDetails, setPointDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCreatorRanking = async () => {
            try {
                setIsLoading(true);
                const response = await apiGetRankingCreator({
                    creator: creatorId,
                    community: communityId,
                    periodo: periodoId,
                });

                console.log("API Response:", response);

                if (response && Array.isArray(response)) {
                    // Se a resposta é um array de pontos
                    if (response.length > 0) {
                        // Extrair informações do criador do primeiro item
                        const firstItem = response[0];
                        const creatorInfo = {
                            name: firstItem.creator_name,
                            avatar_url: "", // A API não está retornando avatar
                            position: "", // A API não está retornando posição
                            total_points: response.reduce((sum, item) => sum + item.points, 0),
                        };
                        setCreatorData(creatorInfo);
                        setPointDetails(response);
                    }
                } else if (response) {
                    // Caso a API retorne o formato esperado anteriormente
                    setCreatorData(response.creator);
                    setPointDetails(response.points || []);
                }
            } catch (err) {
                setError("Não foi possível carregar os detalhes do ranking");
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        if (creatorId && communityId && periodoId) {
            fetchCreatorRanking();
        } else {
            setError("Parâmetros necessários não encontrados");
            setIsLoading(false);
        }
    }, [creatorId, communityId, periodoId]);

    const handleGoBack = () => {
        navigate(-1);
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <S.Container>
            <Box sx={{ px: 2, pt: 3, pb: { xs: 10, sm: 12 } }}>
                <Box display="flex" alignItems="center" mb={2}>
                    <IconButton onClick={handleGoBack} sx={{ mr: 1 }}>
                        <ArrowBackIcon />
                    </IconButton>
                    <S.Title>Detalhes do Ranking</S.Title>
                </Box>

                {creatorData && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            p: 2,
                            mb: 3,
                            borderRadius: 2,
                            bgcolor: "rgba(0, 163, 255, 0.1)",
                        }}
                    >
                        <Avatar src={creatorData.avatar_url} sx={{ width: 64, height: 64, mr: 2 }} />
                        <Box>
                            <Typography variant="h6">{creatorData.name}</Typography>

                            <Typography variant="body1" fontWeight="bold" color="#00A3FF">
                                Total de pontos: {creatorData.total_points}
                            </Typography>
                        </Box>
                    </Box>
                )}

                <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        DETALHAMENTO DE PONTOS
                    </Typography>

                    {pointDetails.length > 0 ? (
                        <List
                            sx={{
                                bgcolor: "background.paper",
                                borderRadius: 2,
                                boxShadow: "0px 2px 8px rgba(0,0,0,0.1)",
                            }}
                        >
                            {pointDetails.map((point, index) => (
                                <React.Fragment key={index}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                            primary={
                                                <Typography fontWeight="bold">
                                                    {point.tipo}
                                                </Typography>
                                            }
                                            secondary={
                                                <>
                                                    <Typography component="span" variant="body2" color="text.primary">
                                                        {point.tipo?.includes("ESTRELA") ? (
                                                            <>Seu vídeo ganhou <b>{point.tipo?.replace("ESTRELA_", "")} estrelas</b> e você recebeu <b>{point.points} pontos</b></>
                                                        ) : point.tipo === "LIKE" ? (
                                                            <>Você curtiu um vídeo e ganhou <b>{point.points} pontos</b></>
                                                        ) : point.tipo === "POST" ? (
                                                            <>Você postou um vídeo e ganhou <b>{point.points} pontos</b></>
                                                        ) : point.tipo === "COMMENT" ? (
                                                            <>Você fez um comentário e ganhou <b>{point.points} pontos</b></>
                                                        ) : point.tipo === "DESAFIO" ? (
                                                            <>Você completou um desafio e ganhou <b>{point.points} pontos</b></>
                                                        ) : point.tipo === "DOWNLOAD" ? (
                                                            <>Você baixou um vídeo e ganhou <b>{point.points} pontos</b></>
                                                        ) : point.tipo === "LINK" ? (
                                                            <>Você clicou em um link e ganhou <b>{point.points} pontos</b></>
                                                        ) : (
                                                            <>Ganhou <b>{point.points} pontos</b> por atividade do tipo <b>{point.tipo}</b></>
                                                        )}
                                                        {point.post ? <> no <b>Post #{point.post}</b></> : ""}
                                                    </Typography>
                                                    {point.created_at && (
                                                        <Typography
                                                            component="p"
                                                            variant="body2"
                                                            color="text.secondary"
                                                        >
                                                            {formatDateString(point.created_at)}
                                                        </Typography>
                                                    )}
                                                </>
                                            }
                                        />
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                color: "#00A3FF",
                                                ml: 2,
                                                alignSelf: "center",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            +{point.points}
                                        </Typography>
                                    </ListItem>
                                    {index < pointDetails.length - 1 && <Divider component="li" />}
                                </React.Fragment>
                            ))}
                        </List>
                    ) : (
                        <Typography align="center" sx={{ my: 4, color: "text.secondary" }}>
                            Nenhum ponto encontrado para este período
                        </Typography>
                    )}
                </Box>

                <Button
                    startIcon={<ArrowBackIcon />}
                    variant="outlined"
                    onClick={handleGoBack}
                    sx={{
                        borderRadius: 2,
                        textTransform: "none",
                        py: 1.5,
                        px: 4,
                    }}
                >
                    Voltar para o ranking
                </Button>
            </Box>
        </S.Container>
    );
};
