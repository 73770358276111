export const getCurrentPeriod = (rankingPeriodos) => {
    if (!rankingPeriodos || rankingPeriodos.length === 0) {
        return null;
    }

    const enabledPeriod = rankingPeriodos.find(periodo => periodo.enable === true);
    
    if (enabledPeriod) {
        return enabledPeriod;
    }
    
    return rankingPeriodos.sort((a, b) => new Date(b.data_fim) - new Date(a.data_fim))[0];
};
