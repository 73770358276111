import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Avatar, IconButton, TextField } from '@mui/material';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import SendIcon from '@mui/icons-material/Send';
import { capitalizerFirstLetter } from '../../utils';

export const CommentsSheet = ({ isOpen, setIsOpen, comments, sendComment }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [comment, setComment] = useState('');
    const [maxHeight, setMaxHeight] = useState('40vh');

    const bottomSheetRef = useRef();

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderComments = () => (
        <>
            <Box sx={{ maxHeight, overflowY: 'auto' }}>
                <Box sx={{ 
                    backgroundColor: '#fff',
                    padding: 3,
                    borderRadius: 2,
                    margin: 2
                }}>
                    <Typography variant="h6" marginBottom={3} fontFamily="var(--title-font)" fontWeight={600}>
                        Comentários
                    </Typography>
                    {comments.map((comment, index) => (
                        <div key={index} style={{ 
                            display: 'flex', 
                            alignItems: 'flex-start', 
                            marginBottom: 18,
                            paddingBottom: 16,
                            borderBottom: index !== comments.length - 1 ? '1px solid var(--grey-50-color)' : 'none'
                        }}>
                            <Avatar className="creatorAvatar" sx={{ width: 30, height: 30, fontSize: 14, mt: 0.8 }}>
                                {comment.usuario?.nome?.charAt(0) ?? 'A'}
                            </Avatar>
                            <div style={{ marginLeft: 8 }}>
                                <div style={{ marginBottom: 5 }}>
                                    <Typography variant="subtitle2" sx={{ lineHeight: 1, fontWeight: 600 }}>
                                        {comment.usuario?.nome || comment.creator?.nome || 'Anônimo'}
                                    </Typography>
                                    <Typography variant="caption" sx={{ color: 'var(--grey-100-color)', fontSize: '0.7rem', ml: 1 }}>
                                        {new Date(comment?.createdAt).toLocaleString()}
                                    </Typography>
                                </div>
                                <Typography variant="body2" sx={{ mt: 1 }}>{capitalizerFirstLetter(comment.texto)}</Typography>
                            </div>
                        </div>
                    ))}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '10px 0',
                            marginTop: 2,
                            borderTop: '1px solid var(--grey-50-color)'
                        }}
                    >
                        <Box style={{ flex: 4, marginRight: 8 }}>
                            <TextField
                                placeholder="Adicionar um comentário..."
                                style={{ width: '100%' }}
                                onChange={(e) => setComment(e.target.value)}
                                value={comment}
                            />
                        </Box>
                        <IconButton
                            onClick={handleSendComment}
                            disabled={!comment.trim()}
                            sx={{ flex: 1 }}
                        >
                            <SendIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </>
    );

    const handleSendComment = async () => {
        await sendComment(comment);
        setComment('');
    };

    return isMobile ? (
        <BottomSheet
            open={isOpen}
            onDismiss={() => setIsOpen(false)}
            ref={bottomSheetRef}
            snapPoints={({ maxHeight }) => [0.6 * maxHeight, maxHeight]}
            defaultSnap={({ maxHeight }) => 0.6 * maxHeight}
            onSpringEnd={(event) => {
                if (event.type === 'SNAP' && event.source === 'drag') {
                    setMaxHeight(event.to === 1 ? '80vh' : '40vh');
                }
            }}
            style={{
                overflowY: 'auto',
                backgroundColor: '#fff',
                position: 'relative',
                maxWidth: '400px',
                margin: '0 auto',
            }}
        >
            {renderComments()}

        </BottomSheet>
    ) : (
        <>
            {renderComments()}

        </>
    );
};
