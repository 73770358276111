import React from "react";
import { Box, ButtonBase, Button, Typography } from "@mui/material";
import * as S from "./styles";

const truncateDescription = (text, isTitle) => {
    if (!text) return "";
    const maxLength = isTitle ? 22 : 50;
    if (text.length <= maxLength) return text;
    return `${text.substring(0, maxLength - 3)}...`;
};

export const OpportunityCard = ({
    opportunity,
    isWizard,
    onClick,
    buttonText = "Saiba mais",
    width = "270px",
    isFullWidth = false,
}) => {
    return (
        <ButtonBase
            onClick={onClick}
            sx={{
                cursor: "pointer",
                width: isFullWidth ? "100%" : width,
                margin: isFullWidth ? 0 : "0 12px 0 0",
            }}
        >
            <S.OpportunityItem isWizard={isWizard} isFullWidth={isFullWidth} className="opportunityCard">
                <Box
                    sx={{
                        width: "100%",
                        height: "60px",
                        display: "grid",
                        alignContent: "end",
                        justifyContent: "end",
                    }}
                >
                    <S.BoldText isWizard={isWizard}>{truncateDescription(opportunity.titulo, true)}&nbsp;</S.BoldText>
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <S.Subtitle isWizard={isWizard}>
                        {opportunity.participationCount > 0 ? `${opportunity.participationCount} participações` : ""}
                    </S.Subtitle>
                    <S.Subtitle isWizard={isWizard}>{`+${opportunity.recompensa_valor || 0} pontos`}</S.Subtitle>
                </Box>
                <Button
                    sx={{
                        backgroundColor: isWizard ? "var(--primary-light-color)" : "var(--primary-color)",
                        color: isWizard ? "var(--primary-color)" : "var(--white-color)",
                        width: isWizard ? "40%" : "100%",
                        padding: isWizard ? "3px 8px" : "4px 30px",
                        textTransform: "none",
                        fontStyle: isWizard ? "italic" : "normal",
                    }}
                    variant="contained"
                >
                    {buttonText}
                </Button>
            </S.OpportunityItem>
        </ButtonBase>
    );
};
