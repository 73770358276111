import React from "react";
import { Box } from "@mui/material";
import { useMobileDetect } from "../../hooks";

export const Gamification = () => {
    const isMobile = useMobileDetect();
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                backgroundColor: "var(--grey-100-color)",
                overflow: "hidden",
                margin: 0,
                padding: 0,
                border: 0,
            }}
        >
            <iframe
                src={`/${isMobile ? "gamification_mobile.pdf#toolbar=0&navpanes=0&scrollbar=0&view=FitW&zoom=100" : "gamification_v3.pdf#toolbar=0&navpanes=0&scrollbar=0&view=FitH"}`}
                width="100%"
                height="100%"
                style={{
                    border: 0,
                    margin: 0,
                    padding: 0,
                    backgroundColor: "var(--grey-100-color)",
                }}
                title="Gamification PDF"
                allowFullScreen
                frameBorder="0"
            ></iframe>
        </Box>
    );
};
