import { Get } from "../http/httpVerbs";

export const apiGetOportunidades = async (params = {}) => {
    try {
        const { campanha, tipo } = params;
        let url = `${process.env.REACT_APP_API_URL}/oportunidades`;

        const response = await Get(url);

        const currentDate = new Date();
        let filteredOpportunities = response.filter((opportunity) => {
            const inscricaoAte = new Date(opportunity.periodo_inscricao_ate);
            return opportunity.status === "Em andamento" && inscricaoAte > currentDate;
        });

        if (campanha) {
            filteredOpportunities = filteredOpportunities.filter((opp) => opp.comunidade === Number(campanha));
        }

        if (tipo) {
            filteredOpportunities = filteredOpportunities.filter((opp) => opp.tipo === tipo);
        }

        return filteredOpportunities;
    } catch (error) {
        console.error("API apiGetOportunidades ERROR:", error);
        throw error;
    }
};
