import { Get } from '../http/httpVerbs';

export const apiGetLogs = async ({ idUsuario, idCreators = [] }) => {
    try {
        const params = new URLSearchParams();
        
        if (idUsuario) params.append("idUsuario", idUsuario);
        if (idCreators.length > 0) params.append("idCreators", JSON.stringify(idCreators));

        const response = await Get(`${process.env.REACT_APP_API_URL}/logs?${params.toString()}`);
        return response;
    } catch (error) {
        throw error;
    }
};
