import React, { useState, useEffect } from "react";
import { Box, ButtonBase, Drawer, Button, Typography } from "@mui/material";
import * as S from "./styles";
import { Opportunity } from "./opportunity";

import { OpportunityCard } from "./opportunityCard";
import { returnEnvironment } from "../../utils";
import { useNavigate } from "react-router-dom";
import { apiGetOportunidades } from "../../services/apiGetOportunidades";
import { useAuthStore } from "../../store/useAuthStore";

export const OpportunitiesBar = ({ community }) => {
    const [selectedOpportunity, setSelectedOpportunity] = useState(null);
    const [opportunities, setOpportunities] = useState([]);
    const creator = useAuthStore((state) => state.creator);

    useEffect(() => {
        const fetchOpportunities = async () => {
            try {
                if (community?.id) {
                    const response = await apiGetOportunidades({ campanha: community.id });
                    setOpportunities(response);
                }
            } catch (error) {
                console.error("Erro ao buscar oportunidades:", error);
            }
        };

        fetchOpportunities();
    }, [community?.id]);

    const validOpportunities = opportunities || [];

    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useNavigate();
    const isWizard = returnEnvironment() === "wizard";

    const openOpportunityModal = (opportunity) => {
        setSelectedOpportunity(opportunity);
        setDrawerOpen(true);
    };

    const closeOpportunityModal = () => {
        setDrawerOpen(false);
        setSelectedOpportunity(null);
    };

    return (
        <>
            <S.Container>
                <S.Title variant="h5">Oportunidades de pontuar</S.Title>
                <S.ScrollContainer>
                    {community && (
                        <OpportunityCard
                            opportunity={{
                                titulo: "Postar no feed",
                                recompensa_valor: 100,
                            }}
                            isWizard={isWizard}
                            onClick={() => navigate(`/novoPost/${community?.id}`, { state: { community } })}
                            buttonText="Postar"
                            width="270px"
                        />
                    )}
                    {validOpportunities &&
                        validOpportunities.length > 0 &&
                        validOpportunities.map((opportunity, index) => (
                            <OpportunityCard
                                key={index}
                                opportunity={opportunity}
                                isWizard={isWizard}
                                onClick={() => openOpportunityModal(opportunity)}
                                buttonText="Saiba mais"
                            />
                        ))}
                </S.ScrollContainer>
                <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", mt: 1 }}>
                    <Typography
                        onClick={() => navigate("/oportunidades", { state: { community } })}
                        sx={{
                            cursor: "pointer",
                            color: "var(--primary-color)",
                            fontWeight: "bold",
                            textDecoration: "underline",
                            fontSize: "var(--font-size-small, 0.85rem)",
                        }}
                    >
                        Ver todos
                    </Typography>
                </Box>
            </S.Container>

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={closeOpportunityModal}
                sx={{
                    "& .MuiDrawer-paper": {
                        padding: 2,
                        width: "100%",
                        maxWidth: "600px",
                        height: "100%",
                        backgroundColor: "var(--white-color)",
                        overflowY: "auto",
                    },
                }}
            >
                {selectedOpportunity && (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Opportunity opportunity={selectedOpportunity} closeModal={closeOpportunityModal} />
                    </Box>
                )}
            </Drawer>
        </>
    );
};
