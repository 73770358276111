import React, { useState, useEffect } from "react";
import { Box, Drawer, Button, Typography } from "@mui/material";
import * as S from "./styles";
import { Opportunity } from "./opportunity";
import { OpportunityCard } from "./opportunityCard";
import { returnEnvironment } from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store/useAuthStore";
import { apiGetOportunidades } from "../../services/apiGetOportunidades";

const OpportunityList = ({ isWizard, onSelect, communityId }) => {
    const [opportunities, setOpportunities] = useState([]);
    const creator = useAuthStore((state) => state.creator);

    useEffect(() => {
        const fetchOpportunities = async () => {
            try {
                if (communityId) {
                    const response = await apiGetOportunidades({ campanha: communityId });
                    setOpportunities(response);
                } else {
                    const response = await apiGetOportunidades();
                    setOpportunities(response);
                }
            } catch (error) {
                console.error("Erro ao buscar oportunidades:", error);
            }
        };

        fetchOpportunities();
    }, [communityId, creator?.id]);

    return (
        <Box sx={{ marginBottom: 3 }}>
            {opportunities?.length === 0 ? (
                <Typography variant="body2">Nenhuma oportunidade encontrada.</Typography>
            ) : (
                opportunities?.map((opportunity, index) => {
                    return (
                        <Box key={index} sx={{ marginBottom: 3 }}>
                            <OpportunityCard
                                opportunity={opportunity}
                                isWizard={isWizard}
                                onClick={() => onSelect(opportunity)}
                                buttonText="Publicar Desafio"
                                isFullWidth={true}
                            />
                        </Box>
                    );
                })
            )}
        </Box>
    );
};

export const Opportunities = () => {
    const [selectedOpportunity, setSelectedOpportunity] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { state } = useLocation();
    const navigate = useNavigate();
    const isWizard = returnEnvironment() === "wizard";

    const handleOpenOpportunity = (opportunity) => {
        setSelectedOpportunity(opportunity);
        setDrawerOpen(true);
    };

    const handleCloseOpportunity = () => {
        setDrawerOpen(false);
        setSelectedOpportunity(null);
    };

    return (
        <>
            <S.Container>
                <S.Title variant="h5">Oportunidades</S.Title>
                <S.ScrollContainer isFullWidth={true}>
                    <Box sx={{ marginBottom: 3 }}>
                        <OpportunityCard
                            opportunity={{
                                titulo: "Postar no feed",
                                recompensa_valor: 100,
                            }}
                            isWizard={isWizard}
                            onClick={() => navigate(`/novoPost/${state?.community?.id}`, { state })}
                            buttonText="Postar"
                            isFullWidth={true}
                        />
                    </Box>
                    <OpportunityList
                        isWizard={isWizard}
                        onSelect={handleOpenOpportunity}
                        isFullWidth={true}
                        communityId={state?.community?.id}
                    />
                    <Box sx={{ width: "100%", marginTop: 3 }}>
                        <Button
                            onClick={() => window.history.back()}
                            variant="contained"
                            fullWidth
                            sx={{
                                backgroundColor: "var(--primary-color)",
                                color: "var(--white-color)",
                                padding: "10px 0",
                                "&:hover": {
                                    backgroundColor: "var(--primary-dark-color)",
                                },
                            }}
                        >
                            VOLTAR
                        </Button>
                    </Box>
                </S.ScrollContainer>
            </S.Container>

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleCloseOpportunity}
                sx={{
                    "& .MuiDrawer-paper": {
                        padding: 2,
                        width: "100%",
                        maxWidth: "600px",
                        height: "100%",
                        backgroundColor: "var(--white-color)",
                        overflowY: "auto",
                    },
                }}
            >
                {selectedOpportunity && (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Opportunity opportunity={selectedOpportunity} closeModal={handleCloseOpportunity} />
                    </Box>
                )}
            </Drawer>
        </>
    );
};
