import React, { useState, useEffect } from "react";
import { Box, Container } from "@mui/material";
import { useMobileDetect } from "../../hooks";
import "./dashboard.css";
import { Loader } from "../../components/ui/Loader";
import { useAuthStore } from "../../store/useAuthStore";
import { apiGetCreators } from "../../services/apiGetCreators";
import { returnCampainId, returnEnvironment } from "../../utils";
import { TermsAcceptanceModal } from "../../components/ui/TermsAcceptanceModal";
import { Patch } from "../../http/httpVerbs";
import { creatorIsAdmin } from "../../utils/adminManager";
import { Menu } from "../../components/ui/Menu";
import { useLocation } from "react-router-dom";
import { Header } from "../../components/ui/Header";
const environment = returnEnvironment();

export const Dashboard = ({ children }) => {
    const creator = useAuthStore((state) => state.creator);
    const updateCreator = useAuthStore((state) => state.updateCreator);
    const isMobile = useMobileDetect();
    const isAdmin = creatorIsAdmin();
    const location = useLocation();
    const isHome = location.pathname === "/" || location.pathname === "";

    const [LoginComponent, setLoginComponent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [comunidade, setComunidade] = useState(null);
    const [showTerms, setShowTerms] = useState(false);
    const [loadingSteps, setLoadingSteps] = useState({
        initial: false,
        hash: false,
        terms: false
    });

    useEffect(() => {
        setLoadingSteps(prev => ({ ...prev, initial: true }));

        const loadLoginComponent = async () => {
            try {
                const isFavelaEnv = process.env.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === "favela";
                if (isFavelaEnv) {
                    const module = await import("../login/favela");
                    setLoginComponent(() => module.Login);
                } else {
                    const environment = returnEnvironment();
                    const module = await import(`../login/${environment}`);
                    setLoginComponent(() => module.Login);
                }
            } catch (error) {
                console.error("Erro ao carregar componente de login:", error);
                try {
                    const defaultModule = await import("../login/default");
                    setLoginComponent(() => defaultModule.Login);
                } catch (defaultError) {
                    console.error("Erro ao carregar o módulo Login default:", defaultError);
                }
            }
        };

        const init = async () => {
            try {
                await loadLoginComponent();
                
                const id = await returnCampainId();
                console.log("Comunidade carregada:", id);
                setComunidade(id);
            } catch (error) {
                console.error("Erro durante a inicialização:", error);
            } finally {
                setLoadingSteps(prev => ({ ...prev, initial: false }));
            }
        };

        init();
    }, []);

    useEffect(() => {
        const checkHashAndFetchCreator = async () => {
            setLoadingSteps(prev => ({ ...prev, hash: true }));
            const urlParams = new URLSearchParams(window.location.search);
            const hash = urlParams.get("hash");

            if (hash) {
                try {
                    const decodedData = atob(hash);
                    const [email, validade] = decodedData.split("|");
                    const agora = new Date();

                    if (new Date(validade) > agora) {
                        const response = await apiGetCreators(email);
                        const creatorData = response[0];

                        if (creatorData) {
                            updateCreator(creatorData);

                            urlParams.delete("hash");

                            window.location.href = "/editUserInfo";
                        }
                    }
                } catch (error) {
                    console.log({ error });
                } finally {
                    setLoadingSteps(prev => ({ ...prev, hash: false }));
                }
            } else {
                setLoadingSteps(prev => ({ ...prev, hash: false }));
            }
        };

        if (!creator) {
            checkHashAndFetchCreator();
        } else {
            setLoadingSteps(prev => ({ ...prev, hash: false }));
        }
    }, [creator, updateCreator]);

    useEffect(() => {
        if (creator && environment === "wizard" && !creator.termos) {
            setShowTerms(true);
        }
    }, [creator]);

    const handleAcceptTerms = async () => {
        try {
            setLoadingSteps(prev => ({ ...prev, terms: true }));
            const url = `${process.env.REACT_APP_API_URL}/creators/${creator.id}`;

            await Patch(url, { termos: true });

            const updatedCreator = {
                ...creator,
                termos: true,
            };

            updateCreator(updatedCreator);
            setShowTerms(false);
        } catch (error) {
            console.error("Erro ao atualizar termos:", error);
        } finally {
            setLoadingSteps(prev => ({ ...prev, terms: false }));
        }
    };

    useEffect(() => {
        const isAnyLoading = Object.values(loadingSteps).some(step => step === true);
        setIsLoading(isAnyLoading);
    }, [loadingSteps]);

    if (isLoading || !LoginComponent) {
        return <Loader />;
    }

    if (!creator) {
        const Login = LoginComponent;
        return <Login />;
    }

    return (
        <Box
            sx={{
                display: "flex",
                minHeight: "100vh",
                backgroundColor: "var(--grey-100-color)",
            }}
        >
            <Box
                sx={{
                    width: isMobile ? 0 : "240px",
                    flexShrink: 0,
                    zIndex: 2,
                }}
            >
                <Menu client={environment} creatorIsAdmin={isAdmin} comunidade={comunidade} />
            </Box>

            <Box
                component="main"
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                }}
            >
                {isMobile && <Header />}
                <Box
                    sx={{
                        flex: 1,
                        overflow: "auto",
                        padding: "16px",
                    }}
                >
                    {React.Children.map(children, (child) => {
                        return React.isValidElement(child) ? React.cloneElement(child, { comunidade }) : child;
                    })}
                </Box>
            </Box>

            <TermsAcceptanceModal open={showTerms} onAccept={handleAcceptTerms} />
        </Box>
    );
};
