import React, { useState, useEffect } from "react";
import {
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Avatar,
    Typography,
    Box,
    IconButton,
    Divider,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Checkbox,
    FormControlLabel,
    List,
    ListItem,
    CardActions,
} from "@mui/material";
import { FavoriteBorder, ChatBubbleOutline, Favorite, Download, Star } from "@mui/icons-material";
import { VideoPlayer } from "../ui/VideoPlayer";
import { CommentsSheet } from "../commentsSheet";
import { apiPostLike, apiDeleteLike, apiDownloadFile } from "../../services";
import { apiPostComment } from "../../services/apiPostComment";
import { apiGetPost } from "../../services";

export const PostCard = ({
    post,
    onClick,
    isPinned,
    currentPlayingVideo,
    setCurrentPlayingVideo,
    videoObserver,
    renderPostContent,
    LinkCard,
    isDetail = false,
    creator,
    showError,
}) => {
    const [likesCount, setLikesCount] = useState(0);
    const [isLiked, setIsLiked] = useState(false);
    const [isCommentsSheetOpen, setIsCommentsSheetOpen] = useState(false);
    const [showModalDownload, setShowModalDownload] = useState(false);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [postData, setPostData] = useState(post);

    const handleAddLike = async () => {
        try {
            const likeData = { idPost: post?.id, creator: creator?.id };
            await apiPostLike(likeData);
            setLikesCount((prev) => prev + 1);
        } catch (error) {
            console.log("Erro", error);
        }
    };

    const handleRemoveLike = async () => {
        try {
            const likeData = { idPost: post?.id, creator: creator.id };
            await apiDeleteLike(likeData);
            setLikesCount((prev) => prev - 1);
        } catch (error) {
            showError(error);
        }
    };

    const handleLikeToggle = async () => {
        if (isLiked) {
            await handleRemoveLike();
        } else {
            await handleAddLike();
        }
        setIsLiked(!isLiked);
    };

    const handleDownload = async (videoFile) => {
        try {
            const downloadData = { post: post.id, creator: creator.id, videoFile };
            const blob = await apiDownloadFile(downloadData);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `${post.id}.mp4`;
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Erro ao baixar o arquivo:", error);
        }
    };

    const fetchPost = async () => {
        try {
            const data = await apiGetPost(post.id);
            setPostData(data);
            const likes = data?.engajamento?.likes || [];
            setLikesCount(likes?.length ?? 0);
            setIsLiked(likes?.some((like) => like.creator === creator?.id));
        } catch (error) {
            showError(error);
        }
    };

    const handleComment = async (comment) => {
        try {
            setIsLoading(true);
            const dataComment = {
                campanha: post.campanha,
                texto: comment,
                idPostParent: post.id,
                creator: creator.id,
            };
            await apiPostComment(dataComment);
            await fetchPost();
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (post) {
            setPostData(post);
            const likes = post?.engajamento?.likes || [];
            setLikesCount(likes?.length ?? 0);
            setIsLiked(likes?.some((like) => like.creator === creator?.id));
        }
    }, [post, creator]);
    return (
        <Card
            sx={{
                maxWidth: {
                    xs: 500,
                    md: 500,
                },
                margin: "auto",
                borderRadius: "16px",
                overflow: "hidden",
                backgroundColor: isPinned ? "var(--primary-dark-color)" : "#FFFFFF",
            }}
        >
            <Box onClick={() => onClick(post)} sx={{ cursor: "pointer" }}>
                <CardHeader
                    avatar={<Avatar className="creatorAvatar">{post.creator?.nome?.charAt(0) ?? "A"}</Avatar>}
                    title={
                        <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            sx={{ color: isPinned ? "var(--white-color)" : "inherit" }}
                        >
                            {post.creator?.nome ?? "Anônimo"}
                        </Typography>
                    }
                    subheader={
                        <Typography sx={{ color: isPinned ? "var(--white-color)" : "inherit", opacity: 0.8 }}>
                            {post?.createdAt ? new Date(post?.createdAt).toLocaleString() : "Data indisponível"}
                        </Typography>
                    }
                />
            </Box>

            {post?.media?.link && (
                <Box sx={{ width: "100%", boxSizing: "border-box", px: 2, mb: 2 }} onClick={() => onClick(post)}>
                    <LinkCard
                        title={
                            <Typography sx={{ color: isPinned ? "var(--white-color)" : "inherit" }}>
                                Compartilhou uma publicação externa:
                            </Typography>
                        }
                        link={post?.media?.link}
                    />
                </Box>
            )}

            {post?.media?.videoUrl && (
                <Box
                    sx={{
                        px: 2,
                        width: "100%",
                        boxSizing: "border-box",
                        cursor: "pointer",
                    }}
                    onClick={() => onClick(post)}
                    ref={(el) => {
                        if (el) {
                            const observer = new IntersectionObserver(
                                (entries) => {
                                    entries.forEach((entry) => {
                                        // Check if at least 400px of the video is visible (about half)
                                        const visibleHeight = entry.intersectionRect.height;
                                        if (entry.isIntersecting && visibleHeight >= 400) {
                                            setCurrentPlayingVideo(post.id);
                                        } else {
                                            if (currentPlayingVideo === post.id) {
                                                setCurrentPlayingVideo(null);
                                            }
                                        }
                                    });
                                },
                                {
                                    threshold: [0, 0.25, 0.5, 0.75, 1],
                                    rootMargin: "-100px 0px",
                                },
                            );

                            observer.observe(el);
                            return () => observer.disconnect();
                        }
                    }}
                >
                    <VideoPlayer
                        url={post.media.videoUrl}
                        showControls={false}
                        autoPlay={currentPlayingVideo === post.id}
                        loop={true}
                        defaultMuted={true}
                        video={{
                            idPost: post.id,
                            idVideo: post.media.idVideo,
                            url: post.media.videoUrl,
                            duration: post.media.duration || 0,
                        }}
                        currentUserId={creator?.id}
                    />
                </Box>
            )}

            {!post?.media?.videoUrl && post?.media?.imageUrl && (
                <Box
                    sx={{
                        width: "100%",
                        maxWidth: { xs: "100vw", sm: "600px" },
                        margin: "0 auto",
                        aspectRatio: "1/1",
                        borderRadius: "16px",
                        overflow: "hidden",
                        cursor: "pointer",
                    }}
                    onClick={() => onClick(post)}
                >
                    <CardMedia
                        component="img"
                        src={post.media.imageUrl}
                        sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                    />
                </Box>
            )}

            <CardContent
                sx={{
                    color: isPinned ? "var(--white-color)" : "inherit",
                    "& .clickable-content": {
                        cursor: "pointer",
                    },
                }}
            >
                <Box className="clickable-content" onClick={() => onClick(post)}>
                    {renderPostContent(post.texto, !isDetail, isPinned)}
                </Box>

                <Divider sx={{ bgcolor: "var(--grey-400-color)", mt: 2 }} />

                {post.categorias && post.categorias.length > 0 && (
                    <div
                        style={{
                            marginTop: "25px",
                            display: "flex",
                            gap: "8px",
                            flexWrap: "wrap",
                        }}
                    >
                        {post.categorias.map((categoria, index) => (
                            <Chip
                                key={index}
                                label={categoria}
                                sx={{
                                    bgcolor: "var(--cattegorychip-color)",
                                    color: "var(--cattegorychiptext-color)",
                                }}
                            />
                        ))}
                    </div>
                )}

                <CardActions sx={{ justifyContent: "space-between" }}>
                    <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
                        <IconButton
                            className="action-button"
                            sx={{
                                color: post.pinned ? "#fff" : "inherit",
                                "& .MuiTypography-root": {
                                    color: post.pinned ? "#fff" : "inherit",
                                },
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsCommentsSheetOpen(!isCommentsSheetOpen);
                            }}
                        >
                            <ChatBubbleOutline fontSize="medium" />
                            <Typography variant="caption" marginLeft={0.5}>
                                {String(postData?.engajamento?.comentarios?.length ?? 0)}
                            </Typography>
                        </IconButton>
                        <IconButton
                            className="action-button"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleLikeToggle();
                            }}
                            sx={{
                                color: isLiked ? "var(--engagement-color)" : post.pinned ? "#fff" : "inherit",
                                "& .MuiTypography-root": {
                                    color: isLiked ? "var(--engagement-color)" : post.pinned ? "#fff" : "inherit",
                                },
                            }}
                        >
                            {isLiked ? <Favorite fontSize="medium" /> : <FavoriteBorder fontSize="medium" />}
                            <Typography variant="caption" marginLeft={0.5} sx={{ color: "var(--engagement-label)" }}>
                                {likesCount}
                            </Typography>
                        </IconButton>
                        {post?.media?.videoUrl && (
                            <IconButton
                                className="action-button"
                                sx={{
                                    color: post.pinned ? "#fff" : "inherit",
                                    "& .MuiTypography-root": {
                                        color: post.pinned ? "#fff" : "inherit",
                                    },
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowModalDownload(true);
                                }}
                            >
                                <Download fontSize="medium" />
                            </IconButton>
                        )}
                    </div>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        {[1, 2, 3].map((star) => {
                            const rating = post?.estrelas ? parseInt(post.estrelas.replace(/ESTRELA_/g, "")) || 0 : 0;
                            return (
                                <Star key={star} fontSize="small" sx={{ color: rating >= star ? "#FFD700" : "#ccc" }} />
                            );
                        })}
                    </Box>
                </CardActions>

                {isCommentsSheetOpen && (
                    <CommentsSheet
                        isOpen={isCommentsSheetOpen}
                        setIsOpen={setIsCommentsSheetOpen}
                        sendComment={handleComment}
                        comments={postData?.engajamento?.comentarios || []}
                    />
                )}
            </CardContent>

            <Dialog open={showModalDownload} onClose={() => setShowModalDownload(false)}>
                <DialogTitle>Leia com atenção:</DialogTitle>
                <DialogContent>
                    <Typography>Você está prestes a realizar o download de um vídeo da nossa comunidade.</Typography>
                    <Typography sx={{ mt: 2 }}>
                        Este conteúdo pode ter sido criado por você ou por outro membro da plataforma. Ao prosseguir com
                        o download, você concorda que:
                    </Typography>
                    <List sx={{ mt: 1, pl: 2 }}>
                        <ListItem sx={{ display: "list-item" }}>
                            <Typography>
                                O uso deste arquivo deve respeitar os Termos de Uso da plataforma, aceitos durante seu
                                cadastro
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                            <Typography>
                                É proibida a distribuição, comercialização ou uso indevido do conteúdo sem autorização
                                expressa do autor
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                            <Typography>
                                O descumprimento destas condições pode resultar em suspensão da conta e penalidades
                                cabíveis
                            </Typography>
                        </ListItem>
                    </List>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isCheckboxChecked}
                                onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                            />
                        }
                        label="Concordo com os termos acima e quero baixar o vídeo"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowModalDownload(false)}>Cancelar</Button>
                    <Button
                        onClick={() => {
                            handleDownload(post.media.videoUrl);
                            setShowModalDownload(false);
                        }}
                        disabled={!isCheckboxChecked}
                        variant="contained"
                        color="primary"
                    >
                        Prosseguir com o download
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};
