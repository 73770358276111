import React from "react";
import {
    Box,
    Typography,
    Button,
    Card,
    CardContent,
    Divider,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import { format } from "date-fns";
import { Loader } from "../../components/ui/Loader";
import { useNavigate } from "react-router-dom";

export const Opportunity = ({ opportunity, closeModal }) => {
    const navigate = useNavigate();

    const formatField = (field, fallback) => {
        if (!field) return fallback;
        if (!isNaN(Date.parse(field))) return format(new Date(field), "dd/MM/yyyy");
        return field;
    };

    const handlePublishChallenge = () => {
        if (!opportunity?.comunidade) return;
        
        navigate(`/novoPost/${opportunity.comunidade}`, {
            state: { community: { id: opportunity.comunidade }, opportunity }
        });
    };

    if (!opportunity) {
        return <Loader />;
    }
    
    return (
        <>
            <Card
                sx={{
                    width: "90%",
                    maxWidth: "100%",
                    px: 5,
                    py: 0,
                    borderRadius: "8px",
                    backgroundColor: "var(--white-color)",
                    boxShadow: "none",
                }}
            >
                {opportunity.titulo && (
                    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                        <Typography variant="h5" fontWeight="bold" sx={{ color: "var(--grey-800-color)" }}>
                            {opportunity.titulo}
                        </Typography>
                        <IconButton onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                )}
                <Divider sx={{ marginBottom: 2 }} />

                <CardContent
                    sx={{
                        padding: 0,
                        maxHeight: "calc(90vh - 100px)",
                        overflowY: "auto",
                        marginBottom: "60px",
                    }}
                >
                    {opportunity.recompensa_valor && (
                        <Box marginBottom={2}>
                            <Typography variant="body1" fontWeight="bold" gutterBottom>
                                Recompensa
                            </Typography>
                            <Box display="flex" alignItems="center" gap={1}>
                                <WorkspacePremiumIcon fontSize="small" />
                                <Typography variant="body1">{opportunity.recompensa_valor} pontos</Typography>
                            </Box>
                        </Box>
                    )}

                    {opportunity.entregas && (
                        <Box marginBottom={2}>
                            <Typography variant="body1" fontWeight="bold" gutterBottom>
                                Entregas
                            </Typography>
                            <Typography variant="body1">{opportunity.entregas}</Typography>
                        </Box>
                    )}
                    <Box marginBottom={2}>
                        <Typography variant="body1" fontWeight="bold" gutterBottom>
                            Prazo para entrega
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1}>
                            <CalendarTodayIcon fontSize="small" />
                            <Typography variant="body1">
                                {formatField(opportunity.prazo_entrega, "Sem prazo definido")}
                            </Typography>
                        </Box>
                    </Box>

                    {opportunity.vagas > 0 && (
                        <Box marginBottom={2}>
                            <Typography variant="body1" fontWeight="bold" gutterBottom>
                                Número de vagas
                            </Typography>
                            <Typography variant="body1">{opportunity.vagas}</Typography>
                        </Box>
                    )}
                    
                    {opportunity.requisitos && (
                        <Box marginBottom={2}>
                            <Typography variant="body1" fontWeight="bold" gutterBottom>
                                Requisitos
                            </Typography>
                            <Typography variant="body1">
                                {opportunity.requisitos || "Sem requisitos definidos"}
                            </Typography>
                        </Box>
                    )}

                    {opportunity.detalhes && (
                        <Box marginBottom={2}>
                            <Typography variant="body1" fontWeight="bold" gutterBottom>
                                Detalhes da oportunidade
                            </Typography>
                            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: opportunity.detalhes }} />
                        </Box>
                    )}
                </CardContent>

                <Box
                    sx={{
                        position: "absolute",
                        bottom: 40,
                        left: 40,
                        right: 40,
                        backgroundColor: "white",
                        zIndex: 1,
                    }}
                >
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handlePublishChallenge}
                        sx={{
                            backgroundColor: "var(--primary-color)",
                            color: "var(--white-color)",
                            "&:hover": {
                                backgroundColor: "var(--primary-light-color)"
                            }
                        }}
                    >
                        PUBLICAR DESAFIO
                    </Button>
                </Box>
            </Card>
        </>
    );
};
