import { useCallback } from "react";
import DOMPurify from "dompurify";

export const useFormattedText = () => {
    const convertUrlsToLinks = useCallback((text) => {
        // Não converte URLs que já estão dentro de tags <a>
        const parts = text.split(/(<a[^>]*>.*?<\/a>)/g);
        return parts.map((part) => {
            if (part.startsWith('<a')) {
                return part;
            }
            const urlRegex = /(https?:\/\/[^\s<]+[^<.,:;"'\]\s])/g;
            return part.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
        }).join('');
    }, []);

    const sanitizeContent = useCallback(
        (htmlContent) => {
            if (!htmlContent) return "";

            // Remove atributos duplicados de href e outras tags aninhadas
            let cleanContent = htmlContent
                .replace(/href="<a[^>]*href="([^"]*)"/g, 'href="$1"')
                .replace(/(<a[^>]*>)([^<]*)(<a[^>]*>)/g, '$1$2')
                .replace(/(<\/a>)([^<]*)(<\/a>)/g, '$1$2');

            const contentWithLinks = convertUrlsToLinks(cleanContent);

            return DOMPurify.sanitize(contentWithLinks, {
                ALLOWED_TAGS: ["p", "strong", "em", "u", "h1", "h2", "h3", "br", "div", "span", "a"],
                ALLOWED_ATTR: ["style", "class", "href", "target", "rel"],
            });
        },
        [convertUrlsToLinks],
    );

    const getTruncatedContent = useCallback((content) => {
        if (!content) return "";

        const firstParagraphMatch = content.match(/<p[^>]*>.*?<\/p>/s);
        if (!firstParagraphMatch) return content;

        const indexAfterFirstP = content.indexOf(firstParagraphMatch[0]) + firstParagraphMatch[0].length;
        const truncatedContent = content.slice(0, indexAfterFirstP);

        return content.length > indexAfterFirstP ? truncatedContent : content;
    }, []);

    return {
        sanitizeContent,
        getTruncatedContent,
    };
};
