import { Get } from "../http/httpVerbs";

export const apiGetPosts = async (page = 1, campanha, filters = {}) => {
    try {
        let url = `${process.env.REACT_APP_API_URL}/posts`;
        let isFirstParam = true;

        // Adiciona o parâmetro page se não for ALL
        if (page !== "ALL") {
            url += `?page=${page}`;
            isFirstParam = false;
        }

        // Adiciona o parâmetro campanha
        if (campanha) {
            url += `${isFirstParam ? '?' : '&'}campanha=${campanha}`;
            isFirstParam = false;
        }

        if (filters.author) {
            url += `${isFirstParam ? '?' : '&'}author=${filters.author}`;
            isFirstParam = false;
        }

        if (filters.editoria) {
            url += `${isFirstParam ? '?' : '&'}editoria=${filters.editoria}`;
            isFirstParam = false;
        }

        if (filters.status) {
            url += `${isFirstParam ? '?' : '&'}status=${filters.status}`;
            isFirstParam = false;
        }

        if (filters.dataInicio) {
            url += `${isFirstParam ? '?' : '&'}dataInicio=${filters.dataInicio}`;
            isFirstParam = false;
        }

        if (filters.dataFim) {
            url += `${isFirstParam ? '?' : '&'}dataFim=${filters.dataFim}`;
            isFirstParam = false;
        }

        if (filters.iLiked) {
            url += `${isFirstParam ? '?' : '&'}iLiked=${filters.iLiked}`;
            isFirstParam = false;
        }

        if (filters.categories && filters.categories.length > 0) {
            url += `${isFirstParam ? '?' : '&'}categories=${filters.categories.join(",")}`;
            isFirstParam = false;
        }

        if (filters.type) {
            url += `${isFirstParam ? '?' : '&'}type=${filters.type}`;
            isFirstParam = false;
        }

        const response = await Get(url);

        return response;
    } catch (error) {
        console.error("API apiGetPosts ERROR:", error);
        throw error;
    }
};
