import React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

export const ManageNavigation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathParts = location.pathname.split("/");
    const communityId = pathParts[pathParts.indexOf("comunidade") + 1];
    const currentPath = pathParts[pathParts.length - 1];

    const handleChange = (event, newValue) => {
        navigate(`/comunidade/${communityId}/gerenciar/${newValue}`);
    };

    const getTabValue = () => {
        switch (currentPath) {
            case "posts":
                return "posts";
            case "membros":
                return "membros";
            case "stats":
                return "stats";
            default:
                return "posts";
        }
    };

    return (
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
            <Tabs
                value={getTabValue()}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                sx={{
                    "& .MuiTab-root": {
                        color: "rgba(0, 0, 0, 0.87)",
                        minWidth: { xs: "140px", sm: "auto" },
                        fontSize: { xs: "0.875rem", sm: "1rem" },
                        padding: { xs: "6px 12px", sm: "12px 16px" },
                        "&.Mui-selected": {
                            color: "var(--primary-color)",
                        },
                    },
                    "& .MuiTabs-indicator": {
                        backgroundColor: "var(--primary-color)",
                    },
                    "& .MuiTabs-scrollButtons": {
                        color: "var(--primary-color)",
                    },
                    "& .MuiTabs-flexContainer": {
                        gap: { xs: "4px", sm: "8px" },
                    },
                }}
            >
                <Tab label="Relatório de Posts" value="posts" />
                <Tab label="Relatório de Membros" value="membros" />
                <Tab label="Stats" value="stats" />
            </Tabs>
        </Box>
    );
};
