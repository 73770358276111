import React, { useState, useEffect } from "react";
import {
    Box,
    Avatar,
    Typography,
    Button,
    LinearProgress,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import * as S from "./styles";
import crownImage from "../../_assets/images/wizard/crown.png";
import { apiGetRanking } from "../../services/apiGetRanking";
import { apiGetRankingPeriodos } from "../../services/apiGetRankingPeriodos";
import { getCurrentPeriod } from "../../utils/getCurrentPeriod";
import { formatDateString } from "../../utils/formatDateString";
import { useMobileDetect } from "../../hooks";
import { creatorIsAdmin, isAdminOfClient } from "../../utils/adminManager";
import { apiGetCommunity } from "../../services/apiGetCommunity";

export const Ranking = () => {
    const navigator = useNavigate();
    const { id } = useParams();
    const [rankingData, setRankingData] = useState([]);
    const [rankingPeriodos, setRankingPeriodos] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [canManage, setCanManage] = useState(false);
    const isMobile = useMobileDetect();

    useEffect(() => {
        const fetchRanking = async () => {
            try {
                setIsLoading(true);

                const periodsResponse = await apiGetRankingPeriodos({ campanha: id });
                setRankingPeriodos(periodsResponse);

                const period = getCurrentPeriod(periodsResponse);
                setCurrentPeriod(period);

                if (period && !selectedPeriod) {
                    setSelectedPeriod(period.id);
                }

                const periodToUse = selectedPeriod ? periodsResponse.find((p) => p.id === selectedPeriod) : period;

                if (periodToUse) {
                    const rankingResponse = await apiGetRanking({ community: id, periodo: periodToUse.id });
                    setRankingData(rankingResponse);
                } else {
                    setRankingData([]);
                }

                if (creatorIsAdmin()) {
                    try {
                        const communityData = await apiGetCommunity(id);
                        if (communityData && isAdminOfClient(communityData.cliente)) {
                            setCanManage(true);
                        }
                    } catch (error) {
                        console.error("Error checking admin status:", error);
                    }
                }
            } catch (err) {
                setError("Não foi possível carregar o ranking");
            } finally {
                setIsLoading(false);
            }
        };

        if (id) {
            fetchRanking();
        }
    }, [id]);

    if (!id) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <Typography color="error">ID da comunidade não encontrado</Typography>
            </Box>
        );
    }

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    const calculateProgress = (startDate, endDate) => {
        const now = new Date();
        const total = new Date(endDate) - new Date(startDate);
        const elapsed = now - new Date(startDate);
        return Math.min(100, Math.max(0, (elapsed / total) * 100));
    };

    const activePeriod = selectedPeriod ? rankingPeriodos.find((p) => p.id === selectedPeriod) : currentPeriod;

    const periodProgress = activePeriod ? calculateProgress(activePeriod.data_inicio, activePeriod.data_fim) : 0;

    const handlePeriodChange = async (event) => {
        const newPeriodId = event.target.value;
        setSelectedPeriod(newPeriodId);

        try {
            setIsLoading(true);
            const rankingResponse = await apiGetRanking({ community: id, periodo: newPeriodId });
            setRankingData(rankingResponse);
        } catch (err) {
            setError("Não foi possível carregar o ranking");
        } finally {
            setIsLoading(false);
        }
    };

    const handleViewUserDetail = (creatorId) => {
        navigator(`/ranking/creator?creator=${creatorId}&community=${id}&periodo=${selectedPeriod}`);
    };

    const renderUserItem = (user) => {
        return (
            <Box
                component={user.current_user ? S.UserRankBox : S.RankingListItem}
                key={user.creator}
                sx={{
                    width: "100%",
                    margin: 0,
                    boxSizing: "border-box",
                    cursor: canManage ? "pointer" : "default",
                }}
                onClick={canManage ? () => handleViewUserDetail(user.creator) : undefined}
            >
                <Typography
                    sx={{
                        minWidth: "40px",
                        pl: 1,
                        mr: 2,
                        fontWeight: 700,
                    }}
                >
                    {user.position}º
                </Typography>
                <Avatar src={user.avatar_url} sx={{ width: 32, height: 32, mr: 2 }} />
                <Box sx={{ flexGrow: 1 }}>
                    <Typography>
                        {user.creator_name}
                        {user.current_user ? " (você)" : ""}
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        color: !user.current_user && "#00A3FF",
                    }}
                >
                    {user.points}
                </Typography>
            </Box>
        );
    };

    const isCurrentUserInTop3 = rankingData.slice(0, 3).some((user) => user.current_user);

    const isCurrentUserInTop10 = rankingData.slice(3, 10).some((user) => user.current_user);

    const currentUser = rankingData.find((user) => user.current_user);

    const handleGoBack = () => navigator(-1);

    return (
        <S.Container>
            {!isMobile && (
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    width="100%"
                    sx={{ px: 2, pt: 3 }}
                ></Box>
            )}
            <Box
                sx={{
                    px: 2,
                    pt: 3,
                    pb: { xs: 10, sm: 12 },
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <S.Title>Ranking</S.Title>
                    {rankingPeriodos.length > 0 && (
                        <FormControl sx={{ minWidth: 200 }} size="small">
                            <InputLabel id="period-select-label">Período</InputLabel>
                            <Select
                                labelId="period-select-label"
                                id="period-select"
                                value={selectedPeriod || ""}
                                label="Período"
                                onChange={handlePeriodChange}
                            >
                                {rankingPeriodos
                                    .sort((a, b) => new Date(b.data_inicio) - new Date(a.data_inicio))
                                    .map((period) => (
                                        <MenuItem key={period.id} value={period.id}>
                                            {formatDateString(period.data_inicio)} - {formatDateString(period.data_fim)}
                                            {period.id === currentPeriod?.id ? " (atual)" : ""}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    )}
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        mb: 2,
                        mx: "auto",
                        maxWidth: { xs: "100%", sm: "90%", md: "80%" },
                    }}
                >
                    {[2, 1, 3].map((position) => {
                        const user = rankingData.find((u) => u.position === position);
                        const placeholder = {
                            creator_id: `placeholder-${position}`,
                            avatar_url: "",
                            creator_name: `${position}º lugar`,
                            position: position,
                            points: "-",
                        };
                        const displayUser = user || placeholder;

                        return (
                            <Box
                                key={displayUser.creator_id}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    mx: { xs: 0.5, sm: 2 },
                                    width: displayUser.position === 1 ? 100 : 80,
                                    opacity: !user ? 0.5 : 1,
                                    cursor: user && canManage ? "pointer" : "default",
                                }}
                                onClick={user && canManage ? () => handleViewUserDetail(user.creator) : undefined}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                        mb: -3,
                                    }}
                                >
                                    <S.PodiumAvatar
                                        src={
                                            displayUser.avatar_url ||
                                            `https://ui-avatars.com/api/?name=${position}&background=FF4B55&color=FFFFFF`
                                        }
                                        isFirst={displayUser.position === 1}
                                    />
                                    {displayUser.position === 1 && (
                                        <Box
                                            component="img"
                                            src={crownImage}
                                            sx={{
                                                position: "absolute",
                                                top: "-20px",
                                                zIndex: "2",
                                                left: "50%",
                                                transform: "translateX(-50%)",
                                                width: { xs: 40, sm: 48 },
                                                height: "auto",
                                                filter: "drop-shadow(0px 2px 4px rgba(0,0,0,0.2))",
                                            }}
                                            alt="Crown"
                                        />
                                    )}
                                </Box>
                                <S.PodiumBox position={displayUser.position}>
                                    <Typography>{displayUser.position}º</Typography>
                                    <Typography>
                                        {displayUser.creator_name}
                                        {displayUser.current_user ? " (você)" : ""}
                                    </Typography>
                                    <Typography>
                                        {displayUser.points}
                                        {displayUser.points !== "-" ? " pts" : ""}
                                    </Typography>
                                </S.PodiumBox>
                            </Box>
                        );
                    })}
                </Box>

                {rankingData.length > 3 && (
                    <S.RankingListContainer>
                        {rankingData.slice(3, 10).map((user) => renderUserItem(user))}
                    </S.RankingListContainer>
                )}

                {!isCurrentUserInTop3 && !isCurrentUserInTop10 && currentUser && currentUser.position > 10 && (
                    <Box sx={{ mb: 4 }}>{renderUserItem(currentUser)}</Box>
                )}

                {activePeriod && (
                    <S.ProgressSection>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            PERÍODO DE PONTUAÇÃO
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            {formatDateString(activePeriod.data_inicio)} até {formatDateString(activePeriod.data_fim)}
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={periodProgress}
                            sx={{
                                height: 8,
                                borderRadius: 4,
                                bgcolor: "#eee",
                                "& .MuiLinearProgress-bar": {
                                    bgcolor: "#FF4B55",
                                },
                            }}
                        />
                        <Typography variant="body2" sx={{ mt: 1, color: "text.secondary" }}>
                            {Math.round(periodProgress)}% do período concluído
                        </Typography>
                    </S.ProgressSection>
                )}

                <S.CallToAction>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        CONTINUE ACUMULANDO PONTOS!
                    </Typography>
                    <Typography sx={{ mb: 3 }}>
                        Participe ativamente na plataforma para subir no ranking e desbloquear recompensas exclusivas.
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => navigator("/gamification")}
                        sx={{
                            bgcolor: "#00A3FF",
                            borderRadius: 2,
                            textTransform: "none",
                            py: 1.5,
                            px: 4,
                            "&:hover": {
                                bgcolor: "#0091EA",
                            },
                        }}
                    >
                        Começar a pontuar
                    </Button>
                </S.CallToAction>
            </Box>
        </S.Container>
    );
};
