import React, { useState, useEffect } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    MenuItem,
    Grid,
    Chip,
    Button,
    Menu,
    ListItemIcon,
    ListItemText,
    Select,
    Checkbox,
    ListItemText as MuiListItemText,
    FormControl,
    InputLabel,
    Card,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { apiGetPosts } from "../../../services/apiGetPosts";
import { apiApprovePost, apiRejectPost, apiDeletePost } from "../../../services/apiPostStatus";
import { apiUpdatePost } from "../../../services/apiUpdatePost";
import { apiPostStarRating } from "../../../services/apiPostStarRating";
import { apiPostRemovePontuacaoDesafio } from "../../../services/apiPostRemovePontuacaoDesafio";
import { useParams } from "react-router-dom";
import { Loader } from "../../../components/ui/Loader";
import { POST_STATUS } from "../../../data/Interfaces";
import { useAuthStore } from "../../../store/useAuthStore";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useFormattedText } from "../../../hooks/useFormattedText";

const statusList = [{ value: "TODOS", label: "Todos" }, ...POST_STATUS];

const getStatusChipColor = (status) => {
    switch (status) {
        case "APROVADO":
            return { bg: "rgba(46, 125, 50, 0.1)", color: "#2E7D32", borderColor: "#2E7D32", tableBg: "#E8F5E9" };
        case "REPROVADO":
            return { bg: "rgba(198, 40, 40, 0.1)", color: "#C62828", borderColor: "#C62828", tableBg: "#FFEBEE" };
        case "PENDENTE":
            return { bg: "rgba(239, 108, 0, 0.1)", color: "#EF6C00", borderColor: "#EF6C00", tableBg: "#FFF3E0" };
        default:
            return { bg: "rgba(97, 97, 97, 0.1)", color: "#616161", borderColor: "#616161", tableBg: "#E0E0E0" };
    }
};

const getStatusLabel = (statusValue) => {
    const status = POST_STATUS.find((s) => s.value === statusValue);
    return status ? status.label : POST_STATUS.find((s) => s.value === "PENDENTE").label;
};

const getStatusValue = (label) => {
    const status = POST_STATUS.find((s) => s.label === label);
    return status ? status.value : "PENDENTE";
};

const MENU_ITEMS = {
    approve: {
        action: "approve",
        icon: <CheckCircleIcon fontSize="small" />,
        text: "Aprovar",
    },
    reject: {
        action: "reject",
        icon: <CancelIcon fontSize="small" />,
        text: "Reprovar",
    },
    delete: {
        action: "delete",
        icon: <DeleteIcon fontSize="small" />,
        text: "Excluir",
    },
    pin: {
        action: "pin",
        icon: <PushPinOutlinedIcon fontSize="small" />,
        text: "Pinar",
    },
    unpin: {
        action: "unpin",
        icon: <PushPinIcon fontSize="small" />,
        text: "Despinar",
    },
    removePontuacao: {
        action: "removePontuacao",
        icon: <RemoveCircleOutlineIcon fontSize="small" />,
        text: "Remover Pontuação Desafio",
    },
};

const getAvailableActions = (status, pinado, post) => {
    const baseActions = [];

    switch (status) {
        case "PENDENTE":
            baseActions.push("approve", "reject", "delete");
            break;
        case "APROVADO":
            baseActions.push("reject", "delete");
            break;
        case "REPROVADO":
            baseActions.push("approve", "delete");
            break;
        default:
            baseActions.push("delete");
    }

    baseActions.push(pinado ? "unpin" : "pin");
    
    // Adiciona a opção de remover pontuação de desafio se o post tiver oportunidadecreator
    if (post?.oportunidadecreator > 0) {
        baseActions.push("removePontuacao");
    }
    
    return baseActions;
};

const StarRating = ({ value, onChange }) => {
    const [rating, setRating] = useState(0);

    useEffect(() => {
        if (value) {
            const match = value.match(/ESTRELA_(\d)/);
            if (match) {
                setRating(parseInt(match[1]));
            }
        }
    }, [value]);

    const handleClick = (newRating) => {
        const nextRating = rating === newRating ? 0 : newRating;
        setRating(nextRating);
        onChange(nextRating === 0 ? null : `ESTRELA_${nextRating}`);
    };

    return (
        <Box
            sx={{
                display: "flex",
                gap: 1,
                position: "relative",
                zIndex: 2,
            }}
        >
            {[1, 2, 3].map((star) => (
                <button
                    key={star}
                    type="button"
                    onClick={() => handleClick(star)}
                    style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        fontSize: "24px",
                        padding: "4px",
                        color: rating >= star ? "#FFD700" : "#ccc",
                    }}
                >
                    ★
                </button>
            ))}
        </Box>
    );
};

export const PostsReport = ({ title = "Posts" }) => {
    const { id: communityId } = useParams();
    const { creator } = useAuthStore();
    const { sanitizeContent, getTruncatedContent } = useFormattedText();
    const [allPosts, setAllPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
    const [filterAuthors, setFilterAuthors] = useState([]);
    const [filterEditorias, setFilterEditorias] = useState([]);
    const [filterStatus, setFilterStatus] = useState(["PENDENTE"]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedPost, setSelectedPost] = useState(null);
    const [isUpdated, setIsUpdated] = useState(false);
    const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
    const [rejectReason, setRejectReason] = useState("");

    const fetchPosts = async () => {
        try {
            setLoading(true);
            setIsUpdated(false);
            const response = await apiGetPosts("ALL", communityId);
            setAllPosts(Array.isArray(response) ? response : []);
            setIsUpdated(true);
        } catch (error) {
            console.error("Erro ao buscar posts:", error);
            setAllPosts([]);
            setIsUpdated(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPosts();
    }, [communityId]);

    const handleAuthorChange = (event) => {
        const value = event.target.value;
        setFilterAuthors(value.includes("todos") ? [] : value.filter((v) => v !== "todos"));
    };

    const handleEditoriaChange = (event) => {
        const value = event.target.value;
        setFilterEditorias(value.includes("todos") ? [] : value.filter((v) => v !== "todos"));
    };

    const handleStatusChange = (event) => {
        const value = event.target.value;
        setFilterStatus(value.includes("todos") ? [] : value.filter((v) => v !== "todos"));
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });
    };

    const handleMenuOpen = (event, post) => {
        setAnchorEl(event.currentTarget);
        setSelectedPost(post);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenRejectDialog = (post) => {
        setSelectedPost(post);
        setRejectDialogOpen(true);
        setAnchorEl(null);
    };

    const handleReject = async () => {
        console.log({ selectedPost, rejectReason });
        if (!selectedPost) return;
        try {
            setIsUpdated(false);
            await apiRejectPost(selectedPost.id, rejectReason);
            await fetchPosts();
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setRejectDialogOpen(false);
            setSelectedPost(null);
            setRejectReason("");
        }
    };

    const handleAction = async (action) => {
        if (!selectedPost) return;

        try {
            setIsUpdated(false);
            switch (action) {
                case "approve":
                    await apiApprovePost(selectedPost.id);
                    break;
                case "reject":
                    handleOpenRejectDialog(selectedPost);
                    break;
                case "delete":
                    await apiDeletePost(selectedPost.id);
                    break;
                case "pin":
                    await apiUpdatePost(selectedPost.id, { pinado: true });
                    break;
                case "unpin":
                    await apiUpdatePost(selectedPost.id, { pinado: false });
                    break;
                case "removePontuacao":
                    await apiPostRemovePontuacaoDesafio(selectedPost.id, selectedPost.oportunidadecreator);
                    break;
            }
            await fetchPosts();
        } catch (error) {
            console.error("Error:", error);
        } finally {
            handleMenuClose();
        }
    };

    const uniqueAuthors = React.useMemo(() => {
        const authors = allPosts
            .filter((post) => post?.creator?.nome)
            .map((post) => post.creator.nome)
            .filter((v, i, a) => a.indexOf(v) === i)
            .sort();
        return ["todos", ...authors];
    }, [allPosts]);

    const uniqueCategorias = React.useMemo(() => {
        const categorias = allPosts
            .reduce((acc, post) => {
                if (post?.categorias && Array.isArray(post.categorias)) {
                    acc.push(...post.categorias);
                }
                return acc;
            }, [])
            .filter((v, i, a) => a.indexOf(v) === i)
            .sort();
        return ["todos", ...categorias];
    }, [allPosts]);

    const filteredPosts = React.useMemo(() => {
        return allPosts.filter((post) => {
            const authorMatch = filterAuthors.length === 0 || filterAuthors.includes(post?.creator?.nome);
            const editoriaMatch =
                filterEditorias.length === 0 || post?.categorias?.some((cat) => filterEditorias.includes(cat));
            const statusMatch =
                filterStatus.includes("TODOS") || filterStatus.length === 0 || filterStatus.includes(post?.status);

            const normalizeDate = (date) => {
                if (!date) return null;
                const d = new Date(date);
                return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, "0")}-${String(d.getDate()).padStart(2, "0")}`;
            };

            const postDate = normalizeDate(post?.createdAt);
            const startDateObj = startDate;
            const endDateObj = endDate;

            const dateMatch =
                (!startDateObj || (postDate && postDate >= startDateObj)) &&
                (!endDateObj || (postDate && postDate <= endDateObj));

            return authorMatch && editoriaMatch && statusMatch && dateMatch;
        });
    }, [allPosts, filterAuthors, filterEditorias, filterStatus, startDate, endDate]);

    const sortedPosts = React.useMemo(() => {
        if (!filteredPosts || !Array.isArray(filteredPosts)) return [];

        let sortedItems = [...filteredPosts];
        if (sortConfig.key) {
            sortedItems.sort((a, b) => {
                if (!a || !b) return 0;

                let valA = a[sortConfig.key];
                let valB = b[sortConfig.key];

                if (sortConfig.key === "creator.nome") {
                    valA = a?.creator?.nome;
                    valB = b?.creator?.nome;
                }

                if (valA < valB) {
                    return sortConfig.direction === "asc" ? -1 : 1;
                }
                if (valA > valB) {
                    return sortConfig.direction === "asc" ? 1 : -1;
                }
                return 0;
            });
        }
        return sortedItems;
    }, [filteredPosts, sortConfig]);

    const renderFormattedText = (content) => {
        if (!content) return null;
        const sanitizedContent = sanitizeContent(content);
        return (
            <Box
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                sx={{
                    wordBreak: "break-word",
                    "& p": { margin: 0 },
                    maxHeight: "120px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            />
        );
    };

    if (!isUpdated) {
        return <Loader />;
    }

    return (
        <Box>
            <Card sx={{ p: 3, mb: 3, mt: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    {title}
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel>Autor</InputLabel>
                            <Select
                                multiple
                                value={filterAuthors}
                                onChange={handleAuthorChange}
                                renderValue={(selected) => selected.join(", ")}
                            >
                                {uniqueAuthors.map((author) => (
                                    <MenuItem key={author} value={author}>
                                        <Checkbox
                                            checked={
                                                author === "todos"
                                                    ? filterAuthors.length === 0
                                                    : filterAuthors.indexOf(author) > -1
                                            }
                                        />
                                        <MuiListItemText primary={author === "todos" ? "Todos os autores" : author} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel>Editoria</InputLabel>
                            <Select
                                multiple
                                value={filterEditorias}
                                onChange={handleEditoriaChange}
                                renderValue={(selected) => selected.join(", ")}
                            >
                                <MenuItem key="todos" value="todos">
                                    <Checkbox checked={filterEditorias.length === 0} />
                                    <MuiListItemText primary="Todas as editorias" />
                                </MenuItem>
                                {uniqueCategorias
                                    .filter((cat) => cat !== "todos")
                                    .map((categoria) => (
                                        <MenuItem key={categoria} value={categoria}>
                                            <Checkbox checked={filterEditorias.indexOf(categoria) > -1} />
                                            <MuiListItemText primary={categoria} />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            fullWidth
                            type="date"
                            label="Data Início"
                            value={startDate}
                            onChange={handleStartDateChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            fullWidth
                            type="date"
                            label="Data Fim"
                            value={endDate}
                            onChange={handleEndDateChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ mt: 3 }}>
                    <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                        {statusList.map((status) => {
                            const isSelected = filterStatus.includes(status.value);
                            const chipColor = getStatusChipColor(status.value === "TODOS" ? "PENDENTE" : status.value);
                            return (
                                <Chip
                                    key={status.value}
                                    label={`${status.label} ${status.value !== "TODOS" ? `(${allPosts.filter((post) => post.status === status.value).length})` : ""}`}
                                    onClick={() => {
                                        if (status.value === "TODOS") {
                                            setFilterStatus(["TODOS"]);
                                        } else {
                                            const newStatus = isSelected
                                                ? filterStatus.filter((s) => s !== status.value)
                                                : [...filterStatus.filter((s) => s !== "TODOS"), status.value];
                                            setFilterStatus(newStatus.length ? newStatus : ["TODOS"]);
                                        }
                                    }}
                                    sx={{
                                        backgroundColor: isSelected ? chipColor.bg : "transparent",
                                        color: isSelected ? chipColor.color : chipColor.color,
                                        border: `1px solid ${isSelected ? chipColor.borderColor : chipColor.borderColor}`,
                                        "&:hover": {
                                            backgroundColor: isSelected ? `${chipColor.bg}` : `${chipColor.bg}50`,
                                        },
                                        cursor: "pointer",
                                        fontWeight: isSelected ? 500 : 400,
                                        height: "32px",
                                    }}
                                />
                            );
                        })}
                    </Box>
                </Box>
            </Card>

            <TableContainer component={Paper} sx={{ overflow: "auto" }}>
                <Table sx={{ minWidth: 650 }}>
                    <colgroup>
                        <col style={{ width: "80px" }} />
                        <col style={{ minWidth: "250px" }} />
                        <col style={{ minWidth: "150px" }} />
                        <col style={{ minWidth: "120px" }} />
                        <col style={{ minWidth: "100px" }} />
                        <col style={{ minWidth: "100px" }} />
                        <col style={{ minWidth: "150px" }} />
                        <col style={{ minWidth: "120px" }} />
                        <col style={{ minWidth: "120px" }} />
                        <col style={{ minWidth: "100px" }} />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell>Cover</TableCell>
                            <TableCell onClick={() => handleSort("texto")} style={{ cursor: "pointer" }}>
                                Texto
                            </TableCell>
                            <TableCell onClick={() => handleSort("creator.nome")} style={{ cursor: "pointer" }}>
                                Autor
                            </TableCell>
                            <TableCell onClick={() => handleSort("createdAt")} style={{ cursor: "pointer" }}>
                                Data
                            </TableCell>
                            <TableCell>Comentários</TableCell>
                            <TableCell>Likes</TableCell>
                            <TableCell>Editorias</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Avaliação</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedPosts.map((post) => (
                            <TableRow
                                key={post?.id}
                                sx={{
                                    "&:hover": {
                                        "& > td": {
                                            backgroundColor: "rgba(0, 0, 0, 0.04) !important",
                                        },
                                        "& .star-rating-cell": {
                                            backgroundColor: "transparent !important",
                                        },
                                    },
                                }}
                            >
                                <TableCell sx={{ position: "relative" }}>
                                    {(post?.media?.imageUrl || post?.media?.videoCapa) && (
                                        <img
                                            src={post?.media?.imageUrl || post?.media?.videoCapa}
                                            alt=""
                                            style={{ width: 45, height: 80, objectFit: "cover" }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={{ position: "relative" }}>
                                    {post?.pinado && (
                                        <PushPinIcon fontSize="small" sx={{ marginRight: 1, color: "primary.main" }} />
                                    )}
                                    <Box
                                        sx={{
                                            minWidth: { xs: "200px", sm: "250px" },
                                            maxWidth: { xs: "200px", sm: "300px", md: "400px" },
                                            whiteSpace: "normal",
                                            wordBreak: "break-word",
                                        }}
                                    >
                                        {renderFormattedText(post?.texto)}
                                    </Box>
                                    <Link
                                        to={`/post/${post?.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            marginLeft: "8px",
                                            color: "inherit",
                                            display: "inline-flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <OpenInNewIcon fontSize="small" />
                                    </Link>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        position: "relative",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "150px",
                                    }}
                                >
                                    {post?.creator?.nome}
                                </TableCell>
                                <TableCell sx={{ position: "relative" }}>
                                    {post?.createdAt && new Date(post.createdAt).toLocaleDateString()}
                                </TableCell>
                                <TableCell sx={{ position: "relative" }}>
                                    {post?.engajamento?.comentarios?.length || 0}
                                </TableCell>
                                <TableCell sx={{ position: "relative" }}>
                                    {post?.engajamento?.likes?.length || 0}
                                </TableCell>
                                <TableCell sx={{ position: "relative" }}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 0.5,
                                            maxWidth: { xs: "120px", sm: "none" },
                                        }}
                                    >
                                        {(post?.categorias || []).map((categoria, index) => (
                                            <Chip
                                                key={index}
                                                label={categoria}
                                                size="small"
                                                sx={{
                                                    maxWidth: { xs: "100px", sm: "none" },
                                                    "& .MuiChip-label": {
                                                        fontSize: { xs: "0.75rem", sm: "0.8125rem" },
                                                    },
                                                }}
                                            />
                                        ))}
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ position: "relative" }}>
                                    <Chip
                                        label={getStatusLabel(post?.status)}
                                        size="small"
                                        sx={{
                                            backgroundColor: getStatusChipColor(post?.status || "PENDENTE").tableBg,
                                            color: getStatusChipColor(post?.status || "PENDENTE").color,
                                        }}
                                    />
                                </TableCell>
                                <TableCell
                                    className="star-rating-cell"
                                    sx={{
                                        position: "relative",
                                        padding: "8px",
                                        backgroundColor: "transparent !important",
                                        "& > *": {
                                            position: "relative",
                                            zIndex: 2,
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: "white",
                                            borderRadius: "4px",
                                            padding: "4px",
                                        }}
                                    >
                                        <StarRating
                                            value={post?.estrelas}
                                            onChange={async (newValue) => {
                                                try {
                                                    // Atualiza localmente
                                                    const updatedPosts = allPosts.map((p) =>
                                                        p.id === post.id ? { ...p, estrelas: newValue } : p,
                                                    );
                                                    setAllPosts(updatedPosts);

                                                    // Chama API
                                                    if (newValue) {
                                                        await apiPostStarRating(post.id, newValue, post.creator.id);
                                                    }
                                                } catch (error) {
                                                    console.error("Erro ao atualizar estrelas:", error);
                                                    alert("Erro ao atualizar avaliação");
                                                }
                                            }}
                                        />
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ position: "relative" }}>
                                    <Button
                                        onClick={(e) => handleMenuOpen(e, post)}
                                        sx={{ zIndex: 2, position: "relative" }}
                                    >
                                        <MoreVertIcon />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {getAvailableActions(selectedPost?.status, selectedPost?.pinado, selectedPost).map((actionKey) => {
                    const menuItem = MENU_ITEMS[actionKey];
                    return (
                        <MenuItem key={actionKey} onClick={() => handleAction(menuItem.action)}>
                            <ListItemIcon>{menuItem.icon}</ListItemIcon>
                            <ListItemText>{menuItem.text}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>

            <Dialog open={rejectDialogOpen} onClose={() => setRejectDialogOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>Motivo da Rejeição</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Digite o motivo da rejeição"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={rejectReason}
                        onChange={(e) => setRejectReason(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setRejectDialogOpen(false)}>Cancelar</Button>
                    <Button onClick={handleReject} variant="contained" color="primary" disabled={!rejectReason.trim()}>
                        Reprovar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
