export { capitalizerFirstLetter } from ".//captalizerFirstLetter.utils";
export { alertColor } from "./alertColors.utils";
export { formatDate } from "./formatDate.utils";
export { removeSpacesFromString } from "./removeSpacesFromString.utils";
export { emailValidator } from "./emailValidator.utils";
export { generateLink } from "./generateLink.utils";
export { getFirstLetterString } from "./getFirstLetterString.utils";
export { getIconByCategory } from "./getIconByCategory.utils";
export { isDisabled } from "./isDisabled.utils";
export { identifyFileType } from "./identifyFileType.utils.js";
export * from "./getStatusColor.utils";
export * from "./forms.utils";
export * from "./isLightOrDark.utils";
export * from "./formsDefaultValues.utils";

export const parseHTMLToText = (encodedHtmlString) => {
    const decodedHtml = decodeURIComponent(encodedHtmlString);

    return <div dangerouslySetInnerHTML={{ __html: decodedHtml }} />;
};

export const returnEnvironment = () => {
    const customizedEnvironments = ["wizard"];
    const environment = process.env.REACT_APP_ENVIRONMENT?.toLocaleLowerCase();
    return customizedEnvironments.includes(environment) ? environment : "default";
};

export const returnCampainId = async () => {
    const { apiGetCommunities } = require("../services");
    const { useAuthStore } = require("../store/useAuthStore");

    try {
        const creator = useAuthStore.getState().creator;
        const cliente = creator?.cliente;

        const result = await apiGetCommunities();
        const filtered = result.filter((item) => item.type === "COMMUNITY" && (!cliente || item.cliente == cliente));

        if (filtered.length === 1) {
            return filtered[0].id;
        }

        if (filtered.length > 1) {
            const sorted = [...filtered].sort((a, b) => a.id - b.id);
            return sorted[0].id;
        }

        return null;
    } catch (error) {
        console.error("Error fetching campaign ID:", error);
        return null;
    }
};
