import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { apiGetPost } from "../../services";
import { useAuthStore } from "../../store/useAuthStore";
import { useErrorStore } from "../../store/useErrorStore";
import { Loader } from "../../components/ui/Loader";
import * as S from "./styles";

import { LinkCard } from "../../components/post/linkCard";
import { useFormattedText } from "../../hooks/useFormattedText";
import { PostCard } from "../../components/post/PostCard";

export const PostDetails = () => {
    const creator = useAuthStore((state) => state.creator);
    const showError = useErrorStore((state) => state.showError);

    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(null);
    const [post, setPost] = useState({});

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const { sanitizeContent } = useFormattedText();

    const fetchData = async () => {
        try {
            setIsLoading(true);
            if (id) {
                const data = await apiGetPost(id);
                setPost(data);
            }
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };



    useEffect(() => {
        fetchData();
    }, [id]);

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 2,
                width: "90%",
                marginTop: 2,
                padding: "0 16px",
                justifyContent: "center",
            }}
        >
            {!isMobile && (
                <Button
                    startIcon={<ArrowBack />}
                    onClick={() => navigate(-1)}
                    sx={{
                        color: "var(--grey-900-color)",
                        textTransform: "none",
                        fontSize: "0.95rem",
                        padding: "8px 16px",
                        minWidth: "auto",
                        borderRadius: "8px",
                        backgroundColor: "var(--grey-50-color)",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        "&:hover": {
                            backgroundColor: "var(--grey-100-color)",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        },
                    }}
                >
                    Voltar
                </Button>
            )}
            <S.StyledCard>
                {isLoading && <Loader />}
                <PostCard
                    post={post}
                    onClick={() => {}}
                    isPinned={false}
                    currentPlayingVideo={null}
                    setCurrentPlayingVideo={() => {}}
                    videoObserver={null}
                    renderPostContent={(text, truncate, isPinned) => (
                        <Box
                            dangerouslySetInnerHTML={{ __html: sanitizeContent(text || "") }}
                            sx={{
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                mt: 1,
                                "& p": { margin: 0 },
                                "& *": {
                                    wordWrap: "break-word",
                                    overflowWrap: "break-word",
                                },
                            }}
                        />
                    )}
                    LinkCard={LinkCard}
                    isDetail={true}
                    creator={creator}
                    showError={showError}

                />
            </S.StyledCard>
        </Box>
    );
};
