import { Get } from "../http/httpVerbs";

export const apiGetCommunities = async () => {
    try {
        const response = await Get(`${process.env.REACT_APP_API_URL}/comunidades?noFilter=true`);
        return response;
    } catch (error) {
        throw error;
    }
};
