import { Get } from "../http/httpVerbs";

export const apiGetCommunityStats = async (campanha, filters = {}) => {
    try {
        let url = `${process.env.REACT_APP_API_URL}/posts/stats`;
        let isFirstParam = true;

        if (campanha) {
            url += `${isFirstParam ? "?" : "&"}campanha=${campanha}`;
            isFirstParam = false;
        }

        if (filters.dataInicio) {
            url += `${isFirstParam ? "?" : "&"}dataInicio=${filters.dataInicio}`;
            isFirstParam = false;
        }

        if (filters.dataFim) {
            url += `${isFirstParam ? "?" : "&"}dataFim=${filters.dataFim}`;
            isFirstParam = false;
        }

        const response = await Get(url);

        return response;
    } catch (error) {
        console.error("API apiGetCommunityStats ERROR:", error);
        throw error;
    }
};
