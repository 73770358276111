import { Typography, Paper, Box, Avatar } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled(Box)(({ theme }) => ({
    backgroundColor: '#F5F5F5',
    minHeight: '100vh',
    width: '100%',
    maxWidth: '100%',
    overflowX: 'hidden',
    paddingBottom: '80px', // Add padding for the fixed navigation
}));

export const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '1.5rem',
    marginBottom: theme.spacing(3),
}));

export const PodiumAvatar = styled(Avatar)(({ isFirst }) => ({
    width: isFirst ? 60 : 50,
    height: isFirst ? 60 : 50,
    border: '2px solid var(--cattegorychip-color)',
    marginBottom: 16,
    position: 'relative',
    zIndex: 2,
    backgroundColor: 'var(--cattegorychip-color)',
    '@media (max-width: 600px)': {
        width: isFirst ? 50 : 40,
        height: isFirst ? 50 : 40,
    }
}));

export const PodiumBox = styled(Box)(({ position }) => ({
    backgroundColor: 'var(--primary-color)',
    borderRadius: '16px 16px 0 0',
    padding: '16px 8px',
    width: position === 1 ? 100 : 80,
    textAlign: 'center',
    color: 'white',
    height: position === 1 ? 140 : position === 2 ? 120 : 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
    '& .MuiTypography-root': {
        fontSize: '0.9rem',
        marginBottom: '4px',
        '&:last-child': {
            marginBottom: 0
        }
    },
    '@media (max-width: 600px)': {
        width: position === 1 ? 80 : 65,
        height: position === 1 ? 120 : position === 2 ? 100 : 80,
        padding: '12px 4px',
        '& .MuiTypography-root': {
            fontSize: '0.8rem'
        }
    }
}));

export const RankingListItem = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
    borderBottom: '1px solid #eee',
    boxSizing: 'border-box',
    '&:last-child': {
        borderBottom: 'none',
    }
});

export const UserRankBox = styled(RankingListItem)({
    backgroundColor: 'var(--primary-color)',
    color: 'white',
});

export const RankingListContainer = styled(Box)({
    backgroundColor: 'white',
    borderRadius: 16,
    overflow: 'hidden',
    marginBottom: 32,
    width: '100%',
    boxSizing: 'border-box',
});

export const ProgressSection = styled(Box)({
    marginBottom: 32,
    '@media (max-width: 600px)': {
        '& .MuiTypography-h6': {
            fontSize: '1rem'
        },
        '& .MuiTypography-body2': {
            fontSize: '0.8rem'
        }
    }
});

export const CallToAction = styled(Box)({
    textAlign: 'center',
    padding: 24,
    backgroundColor: 'white',
    borderRadius: 16,
    '@media (max-width: 600px)': {
        padding: 16,
        '& .MuiTypography-h6': {
            fontSize: '1.1rem'
        },
        '& .MuiTypography-body1': {
            fontSize: '0.9rem'
        }
    }
});
