import React, { useEffect, useState, useRef } from "react";
import { IconButton, Box, Typography } from "@mui/material";
import { recordVideoPlayback } from "../../../services/videoPlaybackService";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import SpeedIcon from "@mui/icons-material/Speed";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

const MUTE_PREFERENCE_KEY = "video-player-mute-preference";
const SPEED_OPTIONS = [0.5, 0.75, 1, 1.25, 1.5, 2];

export const VideoPlayer = ({
    url,
    showControls = true,
    autoPlay = false,
    loop = false,
    defaultMuted = true,
    shouldCleanup = false,
    video,
    currentUserId,
}) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(autoPlay);
    const [isMuted, setIsMuted] = useState(() => {
        const savedPreference = localStorage.getItem(MUTE_PREFERENCE_KEY);
        return savedPreference !== null ? savedPreference === "true" : defaultMuted;
    });
    const [speedIndex, setSpeedIndex] = useState(2);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const containerRef = useRef(null);

    const handleVideoPause = () => {
        if (videoRef.current && video) {
            const watchTime = videoRef.current.currentTime;
            const data = {
                idPost: video.idPost,
                idVideo: video.idVideo,
                urlvideo: video.url,
                videoTotalTime: videoRef.current.duration,
                idcreator: currentUserId,
                watchTime,
            };
            recordVideoPlayback(data).catch((err) => console.error("Erro ao registrar playback:", err));
        }
    };

    const togglePlayPause = (e) => {
        e.stopPropagation();
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play().catch((error) => {
                    console.log("Play failed:", error);
                });
            }
            setIsPlaying(!isPlaying);
        }
    };

    const toggleMute = (e) => {
        e.stopPropagation();
        const newMuteState = !isMuted;
        setIsMuted(newMuteState);
        if (videoRef.current) {
            videoRef.current.muted = newMuteState;
        }
        localStorage.setItem(MUTE_PREFERENCE_KEY, newMuteState.toString());
    };

    const cyclePlaybackSpeed = (e) => {
        e.stopPropagation();
        const newIndex = (speedIndex + 1) % SPEED_OPTIONS.length;
        setSpeedIndex(newIndex);
        if (videoRef.current) {
            videoRef.current.playbackRate = SPEED_OPTIONS[newIndex];
        }
    };

    const toggleFullscreen = async (e) => {
        e.stopPropagation();
        if (!document.fullscreenElement) {
            try {
                await containerRef.current.requestFullscreen();
                setIsFullscreen(true);
            } catch (err) {
                console.error("Error attempting to enable fullscreen:", err);
            }
        } else {
            try {
                await document.exitFullscreen();
                setIsFullscreen(false);
            } catch (err) {
                console.error("Error attempting to exit fullscreen:", err);
            }
        }
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };

        document.addEventListener("fullscreenchange", handleFullscreenChange);
        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.muted = isMuted;
        }
    }, [isMuted]);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            if (autoPlay) {
                videoElement.play().catch((error) => {
                    console.log("AutoPlay failed:", error);
                });
            } else {
                videoElement.pause();
            }
        }

        return () => {
            if (shouldCleanup && videoElement) {
                videoElement.pause();
                videoElement.src = "";
                videoElement.load();
            }
        };
    }, [autoPlay, shouldCleanup]);

    return (
        <div
            ref={containerRef}
            style={{
                position: "relative",
                width: "100%",
                maxWidth: "480px",
                aspectRatio: "480/854",
                margin: "0 auto",
                backgroundColor: "#000",
                overflow: "hidden",
            }}
        >
            <video
                ref={videoRef}
                src={url}
                controls={false}
                autoPlay={autoPlay}
                loop={loop}
                playsInline
                preload="auto"
                width="480"
                height="854"
                onPause={handleVideoPause}
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: isFullscreen ? "contain" : "cover",
                }}
            />
            <Box
                onClick={(e) => e.stopPropagation()}
                sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    padding: "10px",
                    background: "linear-gradient(transparent, rgba(0,0,0,0.7))",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                }}
            >
                <IconButton
                    onClick={togglePlayPause}
                    size="small"
                    sx={{
                        color: "white",
                        padding: "4px",
                        "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                        },
                    }}
                >
                    {isPlaying ? <PauseIcon sx={{ fontSize: 20 }} /> : <PlayArrowIcon sx={{ fontSize: 20 }} />}
                </IconButton>

                <IconButton
                    onClick={toggleMute}
                    size="small"
                    sx={{
                        color: "white",
                        padding: "4px",
                        "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                        },
                    }}
                >
                    {isMuted ? <VolumeOffIcon sx={{ fontSize: 20 }} /> : <VolumeUpIcon sx={{ fontSize: 20 }} />}
                </IconButton>

                <IconButton
                    onClick={cyclePlaybackSpeed}
                    size="small"
                    sx={{
                        color: "white",
                        padding: "4px",
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                        },
                    }}
                >
                    <SpeedIcon sx={{ fontSize: 20 }} />
                    <Typography variant="caption" sx={{ color: "white" }}>
                        {SPEED_OPTIONS[speedIndex]}x
                    </Typography>
                </IconButton>

                <Box sx={{ flexGrow: 1 }} />

                <IconButton
                    onClick={toggleFullscreen}
                    size="small"
                    sx={{
                        color: "white",
                        padding: "4px",
                        "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                        },
                    }}
                >
                    {isFullscreen ? (
                        <FullscreenExitIcon sx={{ fontSize: 20 }} />
                    ) : (
                        <FullscreenIcon sx={{ fontSize: 20 }} />
                    )}
                </IconButton>
            </Box>
        </div>
    );
};
