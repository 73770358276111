import { useInfiniteQuery } from "react-query";
import { apiGetPosts } from "../services";

export const usePosts = (campanha, filters = {}) => {
    return useInfiniteQuery(
        ["posts", campanha, filters],
        async ({ pageParam = 1 }) => {
            const response = await apiGetPosts(pageParam, campanha, filters);
            return {
                posts: response.posts || [],
                hasMore: response.hasNextPage,
                nextPage: response.totalPages > pageParam ? pageParam + 1 : undefined
            };
        },
        {
            getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.nextPage : undefined),
        },
    );
};
