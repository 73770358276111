import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Paper } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const PostSuccess = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [countdown, setCountdown] = useState(30);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(prev => {
                if (prev <= 1) {
                    navigate(`/comunidade/${id}`);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [navigate, id]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                bgcolor: 'background.default',
                p: 3
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: 500,
                    width: '100%',
                    borderRadius: 2,
                    bgcolor: 'background.paper'
                }}
            >
                <Box sx={{
                    animation: 'bounce 1s ease-in-out',
                    '@keyframes bounce': {
                        '0%': { transform: 'scale(0)' },
                        '50%': { transform: 'scale(1.2)' },
                        '100%': { transform: 'scale(1)' },
                    }
                }}>
                    <CheckCircleIcon 
                        sx={{ 
                            fontSize: 100, 
                            color: 'success.main',
                            mb: 2
                        }} 
                    />
                </Box>

                <Box sx={{
                    animation: 'fadeInUp 0.8s ease-out',
                    '@keyframes fadeInUp': {
                        '0%': { opacity: 0, transform: 'translateY(20px)' },
                        '100%': { opacity: 1, transform: 'translateY(0)' },
                    }
                }}>
                    <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                        Post Criado com Sucesso!
                    </Typography>
                </Box>

                <Box sx={{
                    animation: 'fadeIn 0.8s ease-out 0.3s both',
                    '@keyframes fadeIn': {
                        '0%': { opacity: 0 },
                        '100%': { opacity: 1 },
                    }
                }}>
                    <Typography variant="body1" color="text.secondary" align="center" sx={{ mb: 2 }}>
                        Seu post está passando pelo processo de aprovação.
                        Em breve você poderá visualizá-lo no feed!
                    </Typography>
                </Box>

                <Box sx={{
                    animation: 'fadeIn 0.8s ease-out 0.6s both'
                }}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        Você será redirecionado automaticamente em {countdown} segundos...
                    </Typography>
                </Box>
            </Paper>
        </Box>
    );
};
