import { Put } from "../http/httpVerbs";

export const apiPostRemovePontuacaoDesafio = async (postId, oportunidadecreator) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}/posts/${postId}/removerdesafio/${oportunidadecreator}`;
        const response = await Put(url);
        return response;
    } catch (error) {
        console.error("API apiPostRemovePontuacaoDesafio ERROR:", error);
        throw error;
    }
};
