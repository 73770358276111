import { Put, Delete } from "../http/httpVerbs";

export const apiApprovePost = async (postId) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}/posts/aprovar/${postId}`;
        const response = await Put(url);
        return response;
    } catch (error) {
        console.error("API apiApprovePost ERROR:", error);
        throw error;
    }
};

export const apiRejectPost = async (postId, reason) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}/posts/reprovar/${postId}`;
        const response = await Put(url, { motivo: reason });
        return response;
    } catch (error) {
        console.error("API apiRejectPost ERROR:", error);
        throw error;
    }
};

export const apiDeletePost = async (postId) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}/posts/${postId}`;
        const response = await Delete(url);
        return response;
    } catch (error) {
        console.error("API apiDeletePost ERROR:", error);
        throw error;
    }
};
